import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PricingComponent } from './pricing/pricing.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { FeaturesLayoutComponent } from './home/features/features-layout/features-layout.component';
import { AiHeadhuntingSectionComponent } from './home/features/ai-headhunting-section/ai-headhunting-section.component';
import { AiCandidateSourcingSectionComponent } from './home/features/ai-candidate-sourcing-section/ai-candidate-sourcing-section.component';
import { AiResumeEvaluationSectionComponent } from './home/features/ai-resume-evaluation-section/ai-resume-evaluation-section.component';
import { AiPoweredInterviewsSectionComponent } from './home/features/ai-powered-interviews-section/ai-powered-interviews-section.component';
import { PressComponent } from './home/features/press/press.component';
import { ContactUsComponent } from './home/features/contact-us/contact-us.component';
import { TrainingAndSupportComponent } from './home/features/training-and-support/training-and-support.component';
import { SecurityAndPrivacyComponent } from './home/features/security-and-privacy/security-and-privacy.component';
import { CustomizationComponent } from './home/features/customization/customization.component';
import { SourcingEarlyAccessComponent } from './sourcing-early-access/sourcing-early-access.component';
import { AiRecruiterComponent } from './ai-recruiter/ai-recruiter.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'blog',
    loadChildren: () => import('./blog/blog.module').then((m) => m.BlogModule),
  },
  // {
  //   path: 'pricing',
  //   component: PricingComponent,
  // },
  {
    path: 'about-us',
    component: AboutUsComponent,
  },
  {
    path: 'sourcing',
    component: FeaturesLayoutComponent,
    children: [
      {
        path: '',
        component: SourcingEarlyAccessComponent,
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  // {
  //   path: 'ai',
  //   component: AiRecruiterComponent,
  //   data: { title: 'AI Recruiter | Uptal' },
  // },
  {
    path: 'features',
    component: FeaturesLayoutComponent,
    data: { title: 'Features | Uptal' },
    children: [
      {
        path: '',
        component: AiHeadhuntingSectionComponent,
        data: { title: 'AI Headhunting | Uptal' },
      },
      {
        path: 'ai-headhunting',
        component: AiHeadhuntingSectionComponent,
        data: { title: 'AI Headhunting | Uptal' },
      },
      {
        path: 'ai-candidate-sourcing',
        component: AiCandidateSourcingSectionComponent,
        data: { title: 'AI Candidate Sourcing | Uptal' },
      },
      {
        path: 'ai-resume-evaluation',
        component: AiResumeEvaluationSectionComponent,
        data: { title: 'AI Resume Evaluation | Uptal' },
      },
      {
        path: 'ai-powered-interviews',
        component: AiPoweredInterviewsSectionComponent,
        data: { title: 'AI Powered Interviews | Uptal' },
      },
    ],
  },
  {
    path: 'press',
    component: FeaturesLayoutComponent,
    children: [
      {
        path: '',
        component: PressComponent,
      },
    ],
  },
  {
    path: 'contact-us',
    component: FeaturesLayoutComponent,
    children: [
      {
        path: '',
        component: ContactUsComponent,
      },
    ],
  },
  {
    path: 'training-and-support',
    component: FeaturesLayoutComponent,
    children: [
      {
        path: '',
        component: TrainingAndSupportComponent,
      },
    ],
  },
  {
    path: 'customization',
    component: FeaturesLayoutComponent,
    children: [
      {
        path: '',
        component: CustomizationComponent,
      },
    ],
  },
  {
    path: 'security-and-privacy',
    component: FeaturesLayoutComponent,
    children: [
      {
        path: '',
        component: SecurityAndPrivacyComponent,
      },
    ],
  },
  { path: '**', pathMatch: 'full', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      initialNavigation: 'enabledNonBlocking',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
