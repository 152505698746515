import { MatDialog } from '@angular/material/dialog';
import { HowItWorksVideoComponent } from './../../how-it-works-video/how-it-works-video.component';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { AirtableEmailService } from 'src/app/airtable-email.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-default',
  templateUrl: './home-default.component.html',
  styleUrls: ['./home-default.component.scss'],
})
export class HomeDefaultComponent implements AfterViewInit, OnDestroy, OnInit {
  private videoPlayed = false;
  @ViewChild('myVideo') myVideo: ElementRef<HTMLVideoElement>;

  @ViewChildren('recruitmentModuleContainer')
  recruitmentModuleContainer: QueryList<any>;

  constructor(
    private dialog: MatDialog,
    private titleService: Title,
    private metaService: Meta,
    private el: ElementRef,
    private airtableEmailService: AirtableEmailService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Uptal | Enterprise Recruitment');
    this.metaService.updateTag({
      name: 'description',
      content:
        'Advanced AI-powered resume analysis, insightful one-way and live video interviews, and unmatched headhunting.',
    });
  }

  trustedCompanies = [
    // {
    //   src: 'assets/images/home/company-logos/hareer-logo.jpeg',
    //   height: '80',
    //   w: '328',
    //   h: '251',
    // },
    {
      src: 'assets/images/home/company-logos/sets-logo.webp',
      height: '50',
      w: '160',
      h: '100',
    },
    // {
    //   src: 'assets/images/home/company-logos/bank-albilad-logo.webp',
    //   height: '50',
    // },
    {
      src: 'assets/images/home/company-logos/basmah.png',
      height: '85',
      w: '160',
      h: '160',
    },
    {
      src: 'assets/images/home/company-logos/savola-logo.webp',
      height: '50',
      w: '343',
      h: '147',
    },

    // {
    //   src: 'assets/images/home/company-logos/gca-logo.png',
    //   height: '75',
    //   w: '100',
    //   h: '100',
    // },
    // {
    //   src: 'assets/images/home/company-logos/mcdonalds-logo.webp',
    //   height: '50',
    // },
    // {
    //   src: 'assets/images/home/company-logos/saudi-airlines-logo.webp',
    //   height: '50',
    // },
    // { src: 'assets/images/home/company-logos/gasco-logo.webp', height: '50' },
    // { src: 'assets/images/home/company-logos/wow-logo.jpg', height: '50' },
    // { src: 'assets/images/home/company-logos/', height: '21' },
    {
      src: 'assets/images/home/company-logos/unifonic-logo.svg',
      height: '25',
      w: '183',
      h: '25',
    },
    {
      src: 'assets/images/home/company-logos/cocacola.png',
      height: '60',
      w: '160',
      h: '100',
    },
    {
      src: 'assets/images/home/company-logos/asas-logo.webp',
      height: '80',
      w: '328',
      h: '251',
    },
  ];

  faqs = [
    {
      question: 'What is Uptal and how does it work?',
      answer:
        'Uptal is an AI-powered recruiting software that streamlines the recruitment process, reduces time-to-hire, and enhances organizational capabilities. Our technology provides tailored job descriptions, requirements, and customized interview questions for each position, saving HR managers time and improving the accuracy of candidate selection. Our self-recorded interview feature, combined with AI-driven analysis and evaluation, enables companies to quickly identify and rank top candidates, leading to faster time-to-hire and reduced recruitment costs.',
      isOpen: true,
    },
    {
      question:
        'What advantages does using Uptal offer over using an ATS system in the recruitment process?',
      answer:
        "Uptal offers several advantages over using an ATS system. Firstly, it allows candidates to showcase their skills and personality in a way that goes beyond a simple list of qualifications and skills. This helps to level the playing field for candidates, regardless of their educational background or previous job experience. Secondly, employers benefit from a better sense of the candidate's communication skills, presentation style, and overall fit for the company. This information is often more valuable than a simple list of qualifications and can help employers make better hiring decisions. Finally, Uptal's AI technology provides a more comprehensive evaluation of the candidate. This allows for more informed and accurate hiring decisions to be made, while also reducing the risk of unconscious bias. Overall, using Uptal's self-recorded interviews can give candidates a fighting chance in the recruitment process and help companies make better hiring decisions.",
      isOpen: false,
    },
    {
      question: 'Who can benefit from using Uptal?',
      answer:
        'Uptal is designed to benefit HR managers and recruitment teams in small, medium, and large businesses across various industries. Our technology is ideal for companies with 50-1000 employees who are looking to streamline the recruitment process, reduce time-to-hire, and enhance organizational capabilities while promoting diversity, equity, and inclusion in the workplace.',
      isOpen: false,
    },
    {
      question:
        'How does Uptal help promote diversity, equity, and inclusion in the workplace?',
      answer:
        "Our AI analyzes a candidate's personality to help companies identify employees who align with their culture, improving employee retention and overall organizational performance. Additionally, our commitment to diversity, equity, and inclusion ensures that our technology is designed to mitigate unconscious biases and promote a fair and inclusive recruitment process.",
      isOpen: false,
    },
    {
      question: 'How long does it take to set up Uptal?',
      answer:
        "For small and medium businesses, setting up Uptal is simple and straightforward, and does not require any configuration. Our software is ready to use out of the box, and can be easily integrated with your existing HR system. However, for larger enterprises, we offer tailored configuration services to ensure Uptal is seamlessly integrated with your HR system and meets your specific needs. Simply contact our team and we'll work with you to study your requirements and provide a customized implementation plan.",
      isOpen: false,
    },
    {
      question: "Can I customize Uptal to match my company's branding?",
      answer:
        "Yes, Uptal can be fully customized to match your company's branding, including logos, colors, and other visual elements. This ensures that the platform seamlessly integrates into your company's existing branding and enhances your overall recruitment process.",
      isOpen: false,
    },
    {
      question: 'How secure is Uptal?',
      answer:
        'Uptal is designed with security in mind, and we use state-of-the-art security protocols to protect your data. Our platform is hosted on secure servers and all data is encrypted, ensuring that your sensitive information is always protected.',
      isOpen: false,
    },
    {
      question: 'What level of support does Uptal provide?',
      answer:
        'Uptal provides comprehensive support to all of our customers, including a dedicated customer success team that is available to answer any questions and provide assistance whenever needed. Additionally, we offer training and onboarding services to ensure that your team is fully equipped to use the platform.',
      isOpen: false,
    },
    {
      question: 'What are the pricing options for Uptal?',
      answer:
        'Uptal offers flexible pricing options based on the specific needs and requirements of your company. Our pricing is competitive and transparent, with no hidden fees or charges. Checkout our pricing page for more information.',
      isOpen: false,
    },
    {
      question: 'What is the process for onboarding new users?',
      answer:
        'Our onboarding process is simple and straightforward. We provide training and support for enterprises to ensure that your team is fully equipped to use the platform. Additionally, our customer success team is available to answer any questions and provide assistance whenever needed.',
      isOpen: false,
    },
    // Add more questions and answers here...
  ];

  companiesComment = [
    {
      name: 'Ahmed Khalil',
      title: 'Founder Of MoneyFellows',
      avatar: 'assets/images/avatars/profile1.webp',
      comment: `“Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard “`,
    },
    {
      name: 'Mohammed Othman',
      title: 'Project owner At Edita',
      avatar: 'assets/images/avatars/profile4.webp',
      comment: `“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum“`,
    },
    {
      name: 'Ahmed Ali',
      title: 'Founder, abaqoo',
      avatar: 'assets/images/avatars/profile2.webp',
      comment: `“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate“`,
    },
    {
      name: 'Hanan Ali',
      title: 'Founder, abaqoo',
      avatar: 'assets/images/avatars/profile5.webp',
      comment: `“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate“`,
    },
    {
      name: 'Youssef Maher',
      title: 'Project Manager',
      avatar: 'assets/images/avatars/profile3.webp',
      comment: `“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum“`,
    },
    {
      name: 'Nabih Kodak',
      title: 'Business consultant at Edrakk',
      avatar: 'assets/images/avatars/profile6.webp',
      comment: `“Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard “`,
    },
  ];

  candidatesComment = [
    {
      name: 'Anonymus Candidate',
      title: 'Saudi Arabia',
      // avatar: 'assets/images/avatars/profile1.webp',
      comment: `I actually think that the process was good. Of course, this was unexpected (the actual questions) so it really puts the candidate "on the spot" and you have to "think on your feet" which I think is good.`,
    },
    {
      name: 'Anonymus Candidate',
      title: 'Saudi Arabia',
      avatar: 'assets/images/avatars/profile4.webp',
      comment: `Overall, it's a good interface.`,
    },
    {
      name: 'Anonymus Candidate',
      title: 'Saudi Arabia',
      avatar: 'assets/images/avatars/profile2.webp',
      comment: `It was very delighted experience.`,
    },
    {
      name: 'Anonymus Candidate',
      title: 'Saudi Arabia',
      avatar: 'assets/images/avatars/profile4.webp',
      comment: `My experience was good. The interview was easy and I had no problems. I liked being able to answer questions in my own time. It was comfortable and straightforward for me.`,
    },
    {
      name: 'Anonymus Candidate',
      title: 'Saudi Arabia',
      avatar: 'assets/images/avatars/profile1.webp',
      comment: `It was a great and amazing experience for interview.`,
    },

    {
      name: 'Anonymus Candidate',
      title: 'Saudi Arabia',
      avatar: 'assets/images/avatars/profile2.webp',
      comment: `Simple - no issues.`,
    },
  ];

  recruitmentModules = [
    // {
    //   button: 'AI Candidate Sourcing',
    //   description: `With Uptal's self-recorded interview feature, candidates can easily record their responses to customized interview questions at their own convenience.`,
    //   image: 'assets/images/home/recruitment_modules_lg.webp',
    // },
    {
      media_type: 'video',
      button: 'Resume Evaluation',
      description: `Automatically ranks large volumes of resumes by assigning scores to each candidate. These scores are calculated based on the degree of alignment between the candidate's qualifications and the job requirements, simplifying the initial screening process.`,
      video: 'assets/images/home/features/ats_candidates_small.mp4',
    },
    {
      media_type: 'video',
      button: 'Interviews',
      description: `One-way video interviews allow candidates the flexibility to record their answers at a time that suits them, enhancing efficiency in the initial selection process. For a more personal touch, live interviews can be conducted where a human interviewer interacts directly with candidates. All interviews go through an AI analysis which provides a detailed and accurate summary and scoring of the interview.`,
      video: 'assets/images/home/features/view_candidate_small.mp4',
    },
    {
      media_type: 'image',
      button: 'Headhunting',
      description: `Our AI algorithms are designed to efficiently search through our extensive professional database. This intelligent system meticulously identifies candidates who precisely match your job requirements, inviting them to submit their resumes. Once received, these resumes undergo a rigorous AI assessment, ranking each candidate based on their alignment with the job criteria. Only those achieving a score of 80% or higher are invited for a one-way video interview, further analyzed by our AI for compatibility and qualification. We ensure that for each job opening, clients receive a curated list of 3-5 top-tier candidates, significantly reducing the time and effort traditionally required in candidate sourcing and preliminary assessment. Uptal's AI-Powered Headhunting is not just a service, it's a strategic advantage in acquiring the best talent.`,
      image: 'assets/images/home/features/ai_headhunting.webp',
    },
  ];
  selectedRecruitmentModules = 0;

  interviewsCount = 750;
  get price() {
    return Math.round(13434.5 * this.interviewsCount - 100000)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  formatLabel(value: number): string {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return `${value}`;
  }

  toggleFaq(index: number): void {
    this.faqs[index].isOpen = !this.faqs[index].isOpen;
  }

  ngAfterViewInit(): void {
    // document.addEventListener(
    //   'mousemove',
    //   this.playVideoOnMouseMove.bind(this)
    // );
    // var myVideo = document.getElementById('myVideo') as HTMLVideoElement;
    // if (myVideo) {
    //   myVideo.play();
    // }
  }

  playVideoOnMouseMove() {
    if (!this.videoPlayed) {
      this.myVideo.nativeElement
        .play()
        .then(() => {
          this.videoPlayed = true;
          document.removeEventListener(
            'mousemove',
            this.playVideoOnMouseMove.bind(this)
          );
        })
        .catch((e) => {
          console.error('Video playback failed: ', e);
        });
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      document
        .getElementsByTagName('body')[0]
        .classList.remove('scrollbar-hide');
    }
  }

  savingEmail = false;
  openVideo(): void {
    this.validateEmailKeyUp({ target: { value: this.companyEmail } });
    if (this.validateEmail(this.companyEmail)) {
      const record = [
        {
          fields: {
            Email: this.companyEmail,
          },
        },
      ];
      this.savingEmail = true;
      this.airtableEmailService.createRecords(record).subscribe(
        (result) => {
          this.savingEmail = false;
          this.dialog.open(HowItWorksVideoComponent, {
            width: '90%',
            maxWidth: '1024px',
            panelClass: 'dialog-no-px',
          });
        },
        (error) => {
          this.savingEmail = false;
          console.log(error);
        }
      );
    }
  }

  validateEmail(email): boolean {
    const personalEmailDomains = [
      'gmail.com',
      'yahoo.com',
      'hotmail.com',
      'outlook.com',
      'msn.com',
      'live.com',
      'aol.com',
      'ymail.com',
      'icloud.com',
      'mail.com',
      'zoho.com',
      'inbox.com',
      'fastmail.com',
      'me.com',
      'comcast.net',
      'sbcglobal.net',
      'verizon.net',
      'att.net',
      'gmx.com',
      'web.de',
      't-online.de',
      'orange.fr',
      'libero.it',
      'yandex.com',
      'mail.ru',
      'naver.com',
      'hanmail.net',
      'daum.net',
      'qq.com',
      '163.com',
      '126.com',
      'protonmail.com',
      'tutanota.com',
      'hushmail.com',
    ];

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      return false;
    }

    // Extract the domain from the email
    const emailDomain = email.split('@')[1].toLowerCase();

    // Check if the domain is in the list of personal email domains
    if (personalEmailDomains.includes(emailDomain)) {
      return false; // It's a personal email
    }

    return true; // It's not a personal email, hence a valid company email
  }

  companyEmail;
  emailError = '';
  validateEmailKeyUp(event): void {
    const email = event.target.value;
    const emailValid = this.validateEmail(email);
    if (!emailValid) {
      this.emailError = 'Please enter a valid company email address';
    } else {
      //check if enter pressed
      if (event.keyCode === 13) {
        this.openVideo();
      }
      this.emailError = '';
    }
  }

  // fadeInVideo() {
  //   alert('hi');
  //   var video = document.getElementById('myVideo');
  //   video.style.opacity = '1';
  // }

  onRecruitmentModuleClick(index) {
    this.selectedRecruitmentModules = index;
    this.recruitmentModuleContainer.forEach((item, idx) => {
      const child = item.nativeElement.children?.[0];
      if (child instanceof HTMLVideoElement) {
        if (idx === index) child.play();
        else child.pause();
      }
    });
  }
  getStartedUrl() {
    const subdomain = environment.server === 'dev' ? 'dev' : 'app';
    // return `https://${subdomain}.uptal.com/register?sourcing-plan=get-started`;
    return '/register'
  }
}
