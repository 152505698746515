<div
  #hiringCounter
  class="page-max-width page-default-padding py-24 space-y-16"
>
  <div data-aos="fade" class="space-y-5 !max-w-screen-lg m-auto">
    <p class="text-center text-2xl md:text-3xl font-bold text-gray-700">
      Accelerated Hiring
    </p>
    <div class="max-w-screen-lg m-auto">
      <p class="text-center text-xl font-thin text-gray-500">
        With Uptal, not only do you significantly reduce your hiring timeline,
        but you also enhance the quality of your hires. Our system ensures
        instant and consistent evaluations, ensuring that you get the best
        candidates in a fraction of the time.
      </p>
    </div>
  </div>
  <div
    *ngIf="hiringCounterShown"
    data-aos="fade"
    class="max-w-screen-lg m-auto flex justify-center"
  >
    <div
      class="grid grid-cols-1 md:grid-cols-3 divide-y divide-blue-200 md:divide-y-0 divide-x-0 md:divide-x max-w-[220px] md:max-w-full flex-1"
    >
      <div class="py-6 md:py-0 md:px-2">
        <p class="text-center text-gray-800 font-bold">Source Candidates</p>
        <p
          class="text-primary-light font-bold text-3xl md:text-5xl text-center py-3"
        >
          <ngx-animated-counter
            [params]="{ start: 0, end: 93, interval: 20, increment: 1 }"
          >
          </ngx-animated-counter>
          <span class="-ms-1.5">%</span>
        </p>
        <p class="text-center text-blue-500">Faster</p>
      </div>
      <div class="py-6 md:py-0 md:px-2">
        <p class="text-center text-gray-800 font-bold">Evaluate Resumes</p>
        <p
          class="text-primary-light font-bold text-3xl md:text-5xl text-center py-3"
        >
          <ngx-animated-counter
            [params]="{ start: 0, end: 99, interval: 20, increment: 1 }"
          >
          </ngx-animated-counter>
          <span class="-ms-1.5">%</span>
        </p>
        <p class="text-center text-blue-500">Faster</p>
      </div>
      <div class="py-6 md:py-0 md:px-2">
        <p class="text-center text-gray-800 font-bold">Interview Candidates</p>
        <p
          class="text-primary-light font-bold text-3xl md:text-5xl text-center py-3"
        >
          <ngx-animated-counter
            [params]="{ start: 0, end: 95, interval: 20, increment: 1 }"
          >
          </ngx-animated-counter>
          <span class="-ms-1.5">%</span>
        </p>
        <p class="text-center text-blue-500">Faster</p>
      </div>
    </div>
  </div>
  <div data-aos="fade-up" class="flex justify-center">
    <a
      [href]="getStartedUrl()"
      target="_blank"
      class="button primary transition-colors whitespace-nowrap"
    >
    Request a Demo
    </a>
  </div>
</div>
