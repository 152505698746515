<div (click)="mobileMenuOpen = false"
  class="fixed bg-black w-full opacity-80 h-full transform transition duration-200 ease-in-out z-40"
  [ngClass]="mobileMenuOpen === true ? 'block' : 'hidden'"></div>
<div
  class="h-screen overflow-y-auto w-72 bg-gray-50 z-40 transform transition duration-200 ease-in-out fixed bg-primary-dark ltr:-translate-x-full rtl:translate-x-full"
  [ngClass]="mobileMenuOpen === true ? '!translate-x-0' : 'opacity-0'">
  <div class="flex justify-end sticky top-0">
    <button *ngIf="mobileMenuOpen === true" (click)="mobileMenuOpen = false" class="flex-none p-2 text-white">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
        class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
      <span class="sr-only">Close Menu</span>
    </button>
  </div>
  <div class="w-full px-4 pb-12 space-y-8 flex flex-col">
    <div>
      <a class="flex" (click)="mobileMenuOpen = false" routerLink="/">
        <img class="h-12 w-auto" src="assets/images/logo_white.svg" alt="Uptal logo" width="618" height="276" />
      </a>
    </div>
    <ul class="text-lg divide-y divide-gray-100">
      <ng-container *ngFor="let item of items">
        <li *ngIf="item.type === 'link'" class="px-4 py-2.5 text-white flex items-center cursor-pointer">
          <a [routerLink]="item.routerLink" [fragment]="item.fragment" (click)="mobileMenuOpen = false">
            {{ item.title }}
          </a>
        </li>
        <li class="px-4 py-2.5 text-white flex items-center cursor-pointer flex justify-between"
          *ngIf="item.type === 'menu'" [matMenuTriggerFor]="moreMenu" (click)="menuItems = item.items">
          <span>{{ item.title }}</span>
          <mat-icon>expand_more</mat-icon>
        </li>
      </ng-container>
      <li class="px-4 py-2.5 text-white flex items-center cursor-pointer">
        <a [href]="getStartedUrl()" target="_blank"> Request a Demo </a>
      </li>
      <mat-menu #moreMenu>
        <a *ngFor="let menuItem of menuItems" [routerLink]="menuItem.routerLink" [fragment]="menuItem.fragment"
          (click)="mobileMenuOpen = false">
          <menu mat-menu-item>
            {{ menuItem.title }}
          </menu>
        </a>
      </mat-menu>
    </ul>
  </div>
</div>
<div class="top-0 z-30 w-full transform bg-transparent transition duration-200 ease-in-out text-white" [ngClass]="{
    'fixed !bg-primary-dark shadow-md block': showFixedMenu && !mobileMenuOpen,
    'absolute': !showFixedMenu
  }">
  <!--
  <div class="h-10 overflow-hidden bg-[#06d98c] text-black flex items-center justify-center" data-aos="fade-down">
    <p class="text-center text-sm" >
      <a [routerLink]="['/', 'ai']" class="underline font-medium">{{ 'sourcing.Click_Here' | translate }}</a>
      <span>
        {{ 'sourcing.to_check_out_our_new_AI_Recruiter' | translate }}
      </span>
    </p>
  </div>
  -->
  <div class="flex items-center justify-between page-max-width page-default-padding z-20 h-10 my-4 sm:px-8"
    [class.!my-2.5]="showFixedMenu">
    <a routerLink="/">
      <img class="h-10 w-auto" [src]="
          darkMode && !showFixedMenu
            ? 'assets/images/logo_wide_sm.webp'
            : 'assets/images/logo_white.svg'
        " alt="Uptal logo" width="618" height="294" />
    </a>
    <div class="flex items-center space-x-1">
      <ul class="hidden space-x-6 lg:inline-flex">
        <ng-container *ngFor="let item of items">
          <li *ngIf="item.type === 'link'">
            <a [routerLink]="item.routerLink" [fragment]="item.fragment" class="navbar-item"
              [class.!text-primary]="darkMode && !showFixedMenu" [ngClass]="{ 'hover:text-light': 'showFixedMenu' }">
              {{ item.title }}
            </a>
          </li>
          <li *ngIf="item.type === 'menu'" (click)="menuItems = item.items" class="navbar-item"
            [class.!text-primary]="darkMode && !showFixedMenu" [ngClass]="{ 'hover:text-light': 'showFixedMenu' }"
            [matMenuTriggerFor]="moreMenu">
            {{ item.title }}
            <mat-icon>expand_more</mat-icon>
          </li>
        </ng-container>
        <mat-menu #moreMenu>
          <menu mat-menu-item *ngFor="let menuItem of menuItems" [routerLink]="menuItem.routerLink"
            [fragment]="menuItem.fragment">
            {{ menuItem.title }}
          </menu>
        </mat-menu>
      </ul>
    </div>
    <div class="flex items-center space-s-2">
      <a [href]="getStartedUrl()" target="_blank" class="button transition-colors !hidden lg:!flex"
        [class.white]="showFixedMenu" [class.primary]="!showFixedMenu">
        Request a Demo
      </a>
      <button class="leading-none flex items-center lg:hidden" (click)="mobileMenuOpen = true">
        <svg [class.text-white]="!darkMode || showFixedMenu" [class.text-primary]="darkMode" class="h-6 w-6" width="24"
          height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.987 13.3431C14.6854 13.3431 17.3837 13.3438 20.0813 13.3408C20.2753 13.3408 20.476 13.3378 20.6632 13.2923C21.2018 13.1625 21.5383 12.6508 21.4965 12.0621C21.4532 11.4549 21.072 11.0349 20.4991 10.9715C20.3514 10.9551 20.2022 10.9506 20.0537 10.9506C14.657 10.9498 9.26106 10.9491 3.86437 10.9513C3.66071 10.9513 3.45108 10.9528 3.25487 10.9998C2.71625 11.1289 2.37905 11.6421 2.42157 12.2292C2.46559 12.8373 2.84605 13.2558 3.419 13.3199C3.56671 13.3363 3.71591 13.3408 3.86437 13.3408C6.57167 13.3423 9.27972 13.3416 11.987 13.3416V13.3431Z"
            fill="currentColor" />
          <path
            d="M15.5337 5.00037C13.9335 5.00037 12.3333 4.99888 10.7331 5.00186C10.2541 5.00261 9.8789 5.20404 9.68568 5.6509C9.48277 6.11865 9.52231 6.58044 9.84757 6.99C10.0781 7.2802 10.4063 7.38912 10.7622 7.38987C13.944 7.3936 17.1257 7.39658 20.3067 7.38912C21.0364 7.38763 21.5153 6.87362 21.4996 6.16267C21.484 5.45619 21.0102 5.00261 20.2776 5.00186C18.6961 4.99963 17.1145 5.00186 15.533 5.00186L15.5337 5.00037Z"
            fill="currentColor" />
          <path
            d="M15.5352 19.2933C17.107 19.2933 18.6797 19.2948 20.2515 19.2933C21.143 19.2926 21.6563 18.6383 21.4511 17.77C21.3258 17.2388 20.9043 16.915 20.3022 16.9038C19.8002 16.8949 19.2974 16.9016 18.7953 16.9016C16.1439 16.9016 13.4926 16.9001 10.8412 16.9023C10.134 16.9023 9.70953 17.2403 9.58868 17.8841C9.44469 18.6488 9.95945 19.2896 10.7353 19.2918C12.3355 19.2971 13.9357 19.2933 15.5359 19.2933H15.5352Z"
            fill="currentColor" />
        </svg>
      </button>
    </div>
  </div>
  <!-- <div *ngIf="domain"
    class="flex items-center bg-orange-100 text-orange-700 text-sm font-medium px-4 py-2 justify-center gap-x-2"
    role="alert">
    <svg class="fill-current w-4 h-4  flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
    </svg>
    <p>For an enhanced experience, please navigate to the website specific to your region. <a [routerLink]="domain"
        class="hover:underline text-blue-500">here</a></p>
  </div> -->
</div>
